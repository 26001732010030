import { Module } from 'vuex';
import { RootState } from '@/store/root-state';
import { IAuthState } from '@/store/auth/i-auth-state';
import { actions } from '@/store/auth/actions';
import { mutations } from '@/store/auth/mutations';
import { getters } from '@/store/auth/getters';

export const state: IAuthState = {
    isAuthenticated: false,
    accessToken: '',
    currentUser: null
};

export const AuthStore: Module<IAuthState, RootState> = {
    state,
    actions,
    getters,
    mutations
};
