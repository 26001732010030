export class SettingColumnResult {
    id: string;
    moduleName: string;
    userId: string;
    columnConfigData: SettingColumnSelect[];

    constructor(moduleName = '') {
        this.moduleName = moduleName;
    }
}

export class SettingColumnSelect {
    name: string;
    field: string;
    isSelected: boolean;
    isRequired: boolean;
    editable: boolean;
    nameDisplay: string;
    sortable: boolean;

    constructor(name: string = '', field: string = '', isSelected: boolean = true, isRequired: boolean = false, editable = false, sortable = false) {
        this.name = name;
        this.field = field;
        this.isSelected = isSelected;
        this.isRequired = isRequired;
        this.editable = editable;
        this.nameDisplay = '';
        this.sortable = sortable;
    }
}
