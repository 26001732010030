import {MutationTree} from 'vuex';
import {IFilterState} from "@/store/filter/filter-state";
import {FilterStateResult} from "@/models/filter/filter-state-result";

export const SET_FILTER_STATE = 'SET_FILTER_STATE';

export const mutations: MutationTree<IFilterState> = {
    [SET_FILTER_STATE](state: IFilterState, payload: FilterStateResult[]) {
        state.filters = payload;
    },
};