import { GetterTree } from 'vuex';
import { IAuthState } from '@/store/auth/i-auth-state';
import { RootState } from '@/store/root-state';

export const getters: GetterTree<IAuthState, RootState> = {
    accessToken: state => state.accessToken,
    isAuthenticated: state =>
        state.accessToken != null &&
        state.accessToken !== undefined &&
        state.accessToken !== '',
    currentUser: state => state.currentUser
};