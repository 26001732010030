import { ActionTree } from 'vuex';
import { IContactState } from '@/store/contact/contact-state';
import { RootState } from '@/store/root-state';
import { Contact } from '@/models/contact/Contact';
import { ADD_NEW_CONTACT } from '@/store/contact/mutation-types';

export const actions: ActionTree<IContactState, RootState> = {
    [ADD_NEW_CONTACT]({commit, state}, payload: Contact) {
        commit(ADD_NEW_CONTACT, payload);
    }
};