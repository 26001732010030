import {Module} from 'vuex';
import {ICustomerState} from './customer-state';
import {RootState} from '../root-state';
import {CustomerConfig} from '@/models/customer/CustomerConfig';
import {actions} from "./actions";
import {getters} from "./getters";
import {mutations} from "./mutations";

export const state: ICustomerState = {
    configLoaded: false,
    config: new CustomerConfig(),
};

export const CustomerStore: Module<ICustomerState, RootState> = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
