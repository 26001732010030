import axios from 'axios';
import { SearchResult } from '@/models/SearchResult';
import { NoteResult } from '@/models/note/NoteResult';
import { Note } from '@/models/note/Note';
import { ActionResult } from '@/models/ActionResult';
import AuthService from '@/components/auth/AuthService';
import { NoteActionType } from '@/store/note/actions';

import store from '@/store';

export class NoteService {
    private readonly _url = `${process.env.VUE_APP_CUSTOMER_URL}`;

    constructor(customerId: number) {
        this._url = `${this._url}customers/${customerId}/notes`;
    }

    async fetchData(): Promise<SearchResult<NoteResult>> {
        this.dispatchLoading(true);
        const result = await axios.get(this._url) as SearchResult<Note>;
        this.dispatchLoading(false);
        const items = result.items.map((note: Note) => {
            return {
                id: note.id,
                icon_class: 'glyphicon glyphicon-comment',
                title: note.creatorFullName,
                userName: note.creatorFullName,
                controls: [
                    {
                        method: 'edit',
                        icon_class: 'fa fa-edit',
                        callback: (item: any) => {
                            console.log(item);
                        }
                    },
                    {
                        method: 'delete',
                        icon_class: 'fa fa-trash',
                        callback: (item: any) => {
                            console.log(item);
                        }
                    }
                ],
                created: note.createTime,
                body: note.content
            } as NoteResult;
        });
        return {
            items,
            totalRows: result.totalRows
        } as SearchResult<NoteResult>;
    }

    async insert(note: Note): Promise<ActionResult<number>> {
        this.dispatchLoading(true);
        const result: ActionResult<number> = await axios.post(this._url, note);
        this.dispatchLoading(false);
        // const user = await new AuthService().currentUser();
        // note.id = result.data;
        // if (user != null) {
        //     note.creatorId = user.uid;
        //     note.creatorFullName = user.fullName;
        //     note.creatorAvatar = user.avatar;
        // }
        await store.dispatch(NoteActionType.ADD_NOTE, note);
        return result;
    }

    async update(note: Note) {
        this.dispatchLoading(true);
        const result: ActionResult<number> = await axios.post(`${this._url}/${note.id}`, note);
        this.dispatchLoading(false);
        return result;
    }

    async deleteCustomer(noteId: number) {
        this.dispatchLoading(true);
        return axios.delete(`${this._url}/${noteId}`)
            .finally(() => this.dispatchLoading(false));
    }

    private async dispatchLoading(isLoading: boolean) {
        // await store.dispatch('loading', isLoading);
    }
}
