import {Gender} from "@/constants/gender";

export enum CustomerType {
    personal,
    company
}

export enum CustomerStatus {
    Potential,
    Official,
    InStore
}

export const ListCustomerType = [{
    id: 0,
    name: 'Cá nhân', data: null
}, {
    id: 1, name: 'Doanh nghiệp',
    data: null
}]

export const ListGender = [{
    id: Gender.female,
    name: 'Nữ',
    data: null
}, {
    id: Gender.male,
    name: 'Nam',
    data: null
}, {
    id: Gender.other,
    name: 'Khác',
    data: null
}]

export const ListStatusApprove = [{
    id: true,
    name: 'Đã duyệt',
    data: null
}, {
    id: false,
    name: 'Không duyệt',
    data: null
}, {
    id: null,
    name: 'Chờ duyệt',
    data: null
}];