












































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DebtResult, DebtType } from "@/views/customer/detail/debt/debt.result";
import { DebtService } from "@/views/customer/detail/debt/DebtService";
import { SearchResult } from "@/models/SearchResult";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';
import moment from "moment";

@Component({
    components: {
        flatPickr,
        vSelect
    }
})
export default class DebtList extends Vue {
    type = DebtType.AccountReceivable;
    totalPages = 0;
    currentPage = 1;
    fromDate = null;
    toDate = null;
    listItem: DebtResult[] = [];
    isLoading = false;
    configDateTimePicker = {
        enableTime: false,
        dateFormat: 'd-m-Y'
    };
    customerId: string;

    @Watch('isLoading')
    onLoading(value: boolean) {
        if (value) {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: '#btnDebtSearch',
                scale: 0.45
            });
        } else {
            this.$vs.loading.close('#btnDebtSearch > .con-vs-loading');
        }
    }

    beforeMount() {
        if (this.$route.params) {
            this.customerId = this.$route.params.id;
        }
    }

    mounted() {
        this.search();
    }

    formatDate(date: any) {
        return moment(date).format('DD-MM-YYYY HH:mm')
    }

    search() {
        const debtService = new DebtService();
        this.isLoading = true;
        debtService.search(this.customerId, this.fromDate, this.toDate, this.type, this.currentPage, 10)
            .then((result: SearchResult<DebtResult>) => {
                this.isLoading = false;
                if (result.code > 0) {
                    this.listItem = result.items;
                    this.totalPages = Math.ceil(result.totalRows / 10);
                }
            })
            .catch(() => this.isLoading = false);
    }
}
