import { ActionTree } from 'vuex';
import { RootState } from '@/store/root-state';
import { IAuthState } from '@/store/auth/i-auth-state';

export const actions: ActionTree<IAuthState, RootState> = {
  accessToken (context, payload) {
    context.commit('accessToken', payload);
  },
  userInfo (context, payload) {
    context.commit('userInfo', payload);
  }
};
