const authUrl = process.env.VUE_APP_AUTH_API;
const postRedirectUri = process.env.VUE_APP_POST_REDIRECT_URI;
const postLogoutRedirectUri = process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI;
const silentRedirectUrl = process.env.VUE_APP_SILENT_REDIRECT_URI;
const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
const scope = process.env.VUE_APP_SCOPE;
const host: string = window.location.origin;

const clientIds: any = {
    'http://crm.thegioibang.com': 'TGB_CRM_VUE',
    'https://crm.thegioibang.com': 'TGB_CRM_VUE',
    'http://app.inka.vn': 'INKA_CRM_VUE',
    'http://izm.inka.vn': 'IZZYME_CRM_VUE',
    'http://localhost:8080': 'INKA_CRM_VUE_LOCAL',
    'http://inka.localhost:8080': 'INKA_CRM_VUE_LOCAL',
    'http://tgb.localhost:8080': 'TGB_CRM_VUE_LOCAL'
};

export const oidcSettings = {
    authority: authUrl,
    clientId: clientIds[host],
    clientSecret: clientSecret,
    redirect_uri: `${host}${postRedirectUri}`,
    responseType: 'id_token token',
    scope: scope,
    automaticSilentRenew: true,
    post_logout_redirect_uri: `${host}${postLogoutRedirectUri}`,
    silentRedirectUri: `${host}${silentRedirectUrl}`,
    filterProtocolClaims: true,
    require_pkce: true,
    prompt: 'login',
    metadata: {
        issuer: authUrl,
        authorization_endpoint: authUrl + '/connect/authorize',
        userinfo_endpoint: authUrl + '/connect/userinfo',
        end_session_endpoint: authUrl + '/connect/endsession',
        jwks_uri: authUrl + '/.well-known/openid-configuration/jwks'
    }
};
