import { MutationTree } from 'vuex';
import { IContactState } from '@/store/contact/contact-state';
import { Contact } from '@/models/contact/Contact';
import { ADD_NEW_CONTACT } from '@/store/contact/mutation-types';

export const mutations: MutationTree<IContactState> = {
    [ADD_NEW_CONTACT](state, payload: Contact) {
        state.listContactAdded = [...state.listContactAdded, payload];
    }

};