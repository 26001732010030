/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList';
import { colors, themeConfig } from '../../themeConfig';


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
    uid: 0,          // From Auth
    displayName: 'John Doe', // From Auth
    about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    photoURL: require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
    status: 'online',
    userRole: 'admin'
};

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser: userDefaults,
    bodyOverlay: false,
    isVerticalNavMenuActive: true,
    mainLayoutType: themeConfig.mainLayoutType || 'vertical',
    navbarSearchAndPinList: navbarSearchAndPinList,
    reduceButton: themeConfig.sidebarCollapsed,
    verticalNavMenuWidth: 'default',
    verticalNavMenuItemsMin: false,
    scrollY: 0,
    starredPages: [],//navbarSearchAndPinList['pages'].data.filter((page: any) => page.isBookmarked),
    allPages: [],
    bookMarkedPages: [],
    theme: themeConfig.theme || 'light',
    themePrimaryColor: colors.primary,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
    callId :"",
    oidcStore: {}
};

export default state;
