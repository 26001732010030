import {AttributeType, DataType, LogicType, OperatorType} from '@/constants/Attribute';
import {AttributeValueResult} from '@/models/attribute/AttributeValueResult';
import {Suggestion} from "@/models/Suggestion";
import {Operator} from "@/models/attribute/Operator";

export class FilterAttributeResult {
    id: string;
    attributeId: string;
    attributeName: string;
    attributeValueId: Date | number | boolean | string | string[] | number[] | boolean [];
    attributeValues: AttributeValueResult[];
    isMultiple: boolean;
    dataType: DataType;
    type: AttributeType; // Không dùng textarea, radioList
    attributeOptions: Suggestion[];
    operator: OperatorType;
    listOperator: Operator[];
    valueTo: any;
    urlSuggestion: string;
    value: any;
    logic: LogicType;

    constructor(attributeId = '', attributeName = 'Họ tên', attributeValueId = '',
                attributeValueName = '', isMultiple = true, type = AttributeType.Input,
                attributeValues = [], attributeOptions: Suggestion[] = [], operator = OperatorType.Equal,
                urlSuggestion = '', logic = LogicType.And, dataType = DataType.String) {
        this.attributeId = attributeId;
        this.attributeValueId = attributeValueId;
        this.attributeName = attributeName;
        this.type = type;
        this.isMultiple = isMultiple;
        this.attributeValues = attributeValues;
        this.attributeOptions = attributeOptions;
        this.operator = operator === undefined ? OperatorType.Equal : operator;
        this.listOperator = [];
        this.urlSuggestion = urlSuggestion;
        this.logic = logic;
        this.dataType = dataType;
    }
}