import { MutationTree } from 'vuex';
import { IAuthState, UserInfo } from '@/store/auth/i-auth-state';

export const mutations: MutationTree<IAuthState> = {
    accessToken(state, payload) {
        state.accessToken = payload;
    },
    userInfo(state, payload: UserInfo) {
        state.currentUser = payload;
    }
};
