import {Operator} from "@/models/attribute/Operator";

export enum AttributeType {
    Input,
    Textarea,
    DropdownList,
    DateTime,
    CheckList,
    RadioList,
    NumberInput,
}

export enum DataType {
    String,
    Byte,
    Short,
    Int,
    Long,
    Double,
    Decimal,
    DateTime,
    Bit
}

export enum LogicType {
    Or,
    And
}

export enum OperatorType {
    Equal = 1,
    NotEqual,
    LessThan,
    GreaterThan,
    LessThanOrEqual,
    GreaterThanOrEqual,
    Between,
    Contains,
    NotContains,
    StartsWith,
    EndsWith
}

export const ListOperator: Operator[] = [
    {
        id: OperatorType.Between,
        value: 'between',
        name: "Trong khoảng",
        dataType: [DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Decimal]
    },
    {
        id: OperatorType.Equal,
        value: '=',
        name: "Bằng",
        dataType: [DataType.String, DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Bit, DataType.Decimal]
    },
    {
        id: OperatorType.NotEqual,
        value: '<>',
        name: "Không bằng",
        dataType: [DataType.String, DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Bit, DataType.Decimal]
    },
    {
        id: OperatorType.LessThan,
        value: '<',
        name: "Nhỏ hơn",
        dataType: [DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Decimal]
    },
    {
        id: OperatorType.GreaterThan,
        value: '>',
        name: "Lớn hơn",
        dataType: [DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Decimal]
    },
    {
        id: OperatorType.LessThanOrEqual,
        value: '<=',
        name: "Nhỏ hơn hoặc bằng",
        dataType: [DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Decimal]
    },
    {
        id: OperatorType.GreaterThanOrEqual,
        value: '>=',
        name: "Lớn hơn hoặc bằng",
        dataType: [DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Decimal]
    },
    {id: OperatorType.Contains, value: 'contains', name: "Chứa", dataType: [DataType.String]},
    {id: OperatorType.NotContains, value: 'notcontains', name: "Không chứa", dataType: [DataType.String]},
    {id: OperatorType.StartsWith, value: 'startswith', name: "Bắt đầu bằng", dataType: [DataType.String]},
    {id: OperatorType.EndsWith, value: 'endswith', name: "Kết thúc bằng", dataType: [DataType.String]}]

export const ListOperatorSuggestion: Operator[] = [
    {
        id: OperatorType.Equal,
        value: '=',
        name: "Bằng",
        dataType: [DataType.String, DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Bit, DataType.Decimal]
    },
    {
        id: OperatorType.NotEqual,
        value: '<>',
        name: "Không bằng",
        dataType: [DataType.String, DataType.Int, DataType.DateTime, DataType.Byte, DataType.Long, DataType.Double, DataType.Bit, DataType.Decimal]
    }]