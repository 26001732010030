import { MutationTree } from 'vuex';
import { INoteState } from '@/store/note/index';
import { NoteService } from '@/views/customer/note/NoteService';
import { Note } from '@/models/note/Note';
import { NoteResult } from '@/models/note/NoteResult';

export const mutations: MutationTree<INoteState> = {
    fetchData(state: INoteState, payload) {
        new NoteService(payload).fetchData();
    },
    addItem(state: INoteState, payload: Note) {
        const noteResult: NoteResult = {
            id: payload.id,
            body: payload.content,
            userName: payload.creatorFullName,
            title: payload.creatorFullName,
            created: payload.createTime
        } as NoteResult;
        state.listItem = [noteResult, ...state.listItem];
    },
    updateItem(state: INoteState, payload: Note) {
        const note = state.listItem.find((item: NoteResult) => {
            return item.id === payload.id;
        });
        if (note) {
            note.body = payload.content;
        }
    },
    deleteItem(state: INoteState, payload: NoteResult) {
        const index = state.listItem.indexOf(payload);
        if (index !== -1) {
            state.listItem.splice(index, 1);
        }
    }
};
