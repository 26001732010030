export enum Gender {
    female,
    male,
    other
}

export class GenderName {
    id: Gender;
    name: string;

    constructor(id: Gender | null) {
        switch (id) {
            case Gender.female:
                this.name = 'Nữ'
                break;
            case Gender.male:
                this.name = 'Nam';
                break;
            default:
                this.name = 'Khác';
                break;
        }
    }
}
