import { ActionTree } from 'vuex';
import { RootState } from '@/store/root-state';
import { INoteState } from '@/store/note/index';
import { Note } from '@/models/note/Note';

export const NoteActionType = {
    ADD_NOTE: 'note/addItem',
    UPDATE_NOTE: 'note/updateItem',
    DELETE_NOTE: 'note/deleteItem',
    FETCH_DATA: 'note/fetchData'
};

export const actions: ActionTree<INoteState, RootState> = {
    fetchData(context, payload: Note) {
        context.commit('fetchData', payload);
    },
    addItem(context, payload: Note) {
        context.commit('addItem', payload);
    },
    updateItem(context, payload: Note) {
        context.commit('updateItem', payload);
    },
    deleteItem(context, payload: Note) {
        context.commit('deleteItem', payload);
    }
};
