import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import i18n from "@/i18n";
import './interceptor';

const Vuesax = require('vuesax');
// Vuesax Component Framework
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);

// Globally Registered Components
import './globalComponents';


// Styles: SCSS
import './assets/scss/main.scss';


// Tailwind
import '@/assets/css/main.css';

// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer';

Vue.use(VueHammer);

// VeeValidate
import {ValidationProvider, extend} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import './types/declarations/vue-instance-types';

// Feather font icon
require('./assets/css/iconfont.css');

// @ts-ignore
document.onhelp = new Function("return false;");

// @ts-ignore
window.onhelp = new Function("return false;");

// Input mask
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask)

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
