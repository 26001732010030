






import {Component, Vue, Watch} from 'vue-property-decorator';
import {themeConfig} from '../themeConfig';

@Component
export default class App extends Vue {
    vueAppClasses: any = [];

    @Watch('$store.state.theme')
    onThemeChanged(val: any) {
        this.toggleClassInBody(val)
    }

    beforeMount() {
        //console.log('app vue before mount');
        // const authService = new AuthService();
        // authService.getUser().then((user: User | null) => {
        //     if (user != null) {
        //         const profile = user.profile;
        //         this.$store.commit('accessToken', user.access_token);
        //         this.$store.commit(
        //             'userInfo',
        //             new UserInfo(
        //                 profile.sub,
        //                 profile.uid,
        //                 profile.username,
        //                 profile.fullName,
        //                 // profile.email,
        //                 profile.phoneNumber,
        //                 profile.avatar
        //             )
        //         );
        //     } else {
        //         authService.login();
        //     }
        // });
    }

    mounted() {
        this.toggleClassInBody(themeConfig.theme);
        this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    created() {
        let dir = this.$vs.rtl ? 'rtl' : 'ltr';
        document.documentElement.setAttribute('dir', dir);

        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleScroll);
    }

    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleScroll);
    }

    toggleClassInBody(className: any) {
        if (className == 'dark') {
            if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
            document.body.classList.add('theme-dark');
        } else if (className == 'semi-dark') {
            if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
            document.body.classList.add('theme-semi-dark');
        } else {
            if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
            if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
        }
    }

    setAppClasses(classesStr: any) {
        this.vueAppClasses.push(classesStr);
    }

    handleWindowResize() {
        this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

        // Set --vh property
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }

    handleScroll() {
        this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
    }
}
