
import { Module } from 'vuex';
import { TabState, IOrderState } from '@/store/order/order-state';
import { RootState } from '@/store/root-state';
import { actions } from '@/store/order/actions';
import { getters } from '@/store/order/getters';
import { mutations } from '@/store/order/mutations';

export const state: IOrderState = {
    tab: new TabState()
};

export const OrderStore: Module<IOrderState, RootState> = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
