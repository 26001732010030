import {Module} from 'vuex';
import {RootState} from '../root-state';
import {actions} from "./actions";
import {getters} from "./getters";
import {mutations} from "./mutations";
import {IFilterState} from "@/store/filter/filter-state";

export const state: IFilterState = {
    filters: [],
};

export const FilterStore: Module<IFilterState, RootState> = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
