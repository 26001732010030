import {ActionTree} from 'vuex';
import {RootState} from '@/store/root-state';
import {IFilterState} from "@/store/filter/filter-state";
import {SET_FILTER_STATE} from "@/store/filter/mutations";
import {FilterStateResult} from "@/models/filter/filter-state-result";
import * as _ from 'lodash';

export const actions: ActionTree<IFilterState, RootState> = {
    setFilterState({commit, state}, filerState: FilterStateResult) {
        const filers = state.filters && state.filters.length > 0 ? state.filters : [];
        let filterByModule = _.find(filers, (item: FilterStateResult) => {
            return item.module === filerState.module;
        })

        if (filterByModule) {
            filterByModule.filter = filerState.filter;
            filterByModule.currentPage = filerState.currentPage;
            filterByModule.pageSize = filerState.pageSize;
            filterByModule.tabIndex = filerState.tabIndex;
            filterByModule.listFilter = filerState.listFilter;
            filterByModule.searchValue = filerState.searchValue;
            filterByModule.loadOption = filerState.loadOption;
            filterByModule.attributeOptions = filerState.attributeOptions;
        } else {
            filers.push(filerState);
        }
        commit(SET_FILTER_STATE, filers);
    },
};