export enum DebtReferenceType {
    /// <summary>
    /// Đơn hàng.
    /// </summary>
    Order,

    /// <summary>
    /// Trả hàng.
    /// </summary>
    OrderReturned,

    /// <summary>
    /// Phiếu thu.
    /// </summary>
    AccountReceivable,

    /// <summary>
    /// Phiếu chi.
    /// </summary>
    AccountPayable,
}

export enum DebtType {
    /// <summary>
    /// Công nợ phải thu.
    /// </summary>
    AccountReceivable,

    /// <summary>
    /// Công nợ phải chả.
    /// </summary>
    AccountPayable
}

export interface DebtResult {
    referenceId: string;
    referenceType: DebtReferenceType;
    createTime: string;
    debtDate: string;
    totalValue: number;
    closingAccountValue: number;
    isReceivable: boolean;
    type: DebtType;
    creatorId: string;
    creatorFullName: string;
    note: string;
}
