import { FormType } from "@/constants/Order";

export class TabState {
    id: string;
    type: FormType | null;
    
    constructor() {
        this.id = '';
        this.type = null
    }
}

export interface IOrderState {
    tab: TabState;
}