import axios from 'axios';
import { CustomerDetailResult } from '@/models/customer/CustomerDetailResult';
import { SearchResult } from '@/models/SearchResult';
import { ActionResult } from '@/models/ActionResult';
import { Customer } from '@/models/customer/Customer';
import { CustomerResult } from '@/models/customer/CustomerResult';
import { BankResult } from "@/models/bank/BankResult";
import { Suggestion } from "@/models/Suggestion";
import { FilterAttributeResult } from "@/models/attribute/FilterAttributeResult";
import { UserShareModel } from "@/components/user-share/UserShareModel";
import { CustomerSuggestionModel } from '@/components/suggestion/customer-suggestion/CustomerSuggestionModel';
import { CustomerSalesChannelResult } from '@/models/customer/CustomerSalesChannelResult';
import { CustomerConfig } from '@/models/customer/CustomerConfig';
import { QueryLoadOption } from "@/models/QueryLoadOptions";
import { CustomerType, ListCustomerType, ListGender, ListStatusApprove } from "@/constants/Customer";
import { CustomerContact } from "@/models/customer/CustomerContact";
import { AttributeType, DataType, LogicType, OperatorType } from "@/constants/Attribute";
import { SettingColumnSelect } from "@/models/settingColumn/SettingColumnResult";
import { CategoryType } from "@/models/category/CategoryType";
import { Gender } from "@/constants/gender";
import moment from 'moment';

export class CustomerService {
    url = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers`;

    async exportExcel(loadOptions: QueryLoadOption) {
        const result = (await axios.post(`${this.url}/export/`, loadOptions, {responseType: 'blob', })) as any;
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `danh-sach-khach-hang.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        return true;
    }

    search(loadOptions: QueryLoadOption): Promise<SearchResult<CustomerResult>> {
        return axios.post(`${this.url}/search`, loadOptions);
    }

    countByQuery(loadOptions: QueryLoadOption): Promise<number> {
        return axios.post(`${this.url}/count`, loadOptions);
    }

    insert(customer: Customer): Promise<ActionResult<CustomerResult>> {
        return axios.post(`${this.url}`, customer);
    }

    quickInsert(searchTerm: string): Promise<ActionResult<string>> {
        return axios.post(`${this.url}/quick`, searchTerm);
    }

    async update(id: string, customerDetail: CustomerDetailResult): Promise<ActionResult<string>> {
        try {
            return await axios.put(`${this.url}/${id}`, {
                name: customerDetail.name,
                code: customerDetail.code,
                isActive: customerDetail.isActive,
                taxCode: customerDetail.taxCode,
                shortName: customerDetail.shortName,
                agencyId: customerDetail.agencyId,
                foundingDate: customerDetail.foundingDate
                    ? moment(customerDetail.foundingDate, 'DD-MM-YYYY').format()
                    : null,
                website: customerDetail.website,
                representative: customerDetail.representative,
                representativeTitle: customerDetail.representativeTitle,
                representativeOffice: customerDetail.representativeOffice,
                resourceId: customerDetail.resourceId,
                classifyId: customerDetail.classifyId,
                potentialLevelId: customerDetail.potentialLevelId,
                clarificationId: customerDetail.clarificationId,
                strategyId: customerDetail.strategyId,
                focusGroupId: customerDetail.focusGroupId,
                companySizeId: customerDetail.companySizeId,
                companySizeName: customerDetail.companySizeName,
                businessCareerId: customerDetail.businessCareerId,
                revenueId: customerDetail.revenueId,
                revenueName: customerDetail.revenueName,
                description: customerDetail.description,
                concurrencyStamp: customerDetail.concurrencyStamp,
                salesChannelId: customerDetail.salesChannelId,
                salesChannelName: customerDetail.salesChannelName,
                standardLivingId: customerDetail.standardLivingId,
                standardLivingName: customerDetail.standardLivingName,
                type: customerDetail.type,
                phoneNumber: customerDetail.phoneNumber,
                email: customerDetail.email,
                image: customerDetail.image,
                status: customerDetail.status,
                regionId: customerDetail.regionId,
                regionName: customerDetail.regionName,
                gender: customerDetail.gender
            });
        } catch (e) {
            return e.message;
        }
    }

    deleteCustomer(customerId: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${customerId}`);
    }

    getDetail(customerId: string): Promise<ActionResult<CustomerDetailResult>> {
        return axios.get(`${this.url}/${customerId}`);
    }

    async getCustomerByPhoneNumber(phoneNumber: string): Promise<Customer | null> {
        try {
            const response = await axios.get(`${this.url}/phones`, {
                params: {
                    phoneNumber
                }
            });
            return response.data;
        } catch (e) {
            return null;
        }

    }

    getAllBank(): Promise<SearchResult<BankResult>> {
        return axios.get(this.url);
    }

    getBranchByBankId(id: string): Promise<SearchResult<Suggestion>> {
        return axios.get(`${this.url}/${id}/branches`);
    }

    addBank(customerId: string, bankAccount: any): Promise<ActionResult> {
        return axios.post(`${this.url}/${customerId}/banks`, bankAccount);
    }

    removeBank(customerId: string, bankAccountId: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${customerId}/banks/${bankAccountId}`);
    }

    updateShare(id: string, userShare: UserShareModel[]): Promise<ActionResult> {
        return axios.post(`${this.url}/${id}/share`, userShare.map((userShare: UserShareModel) => {
            return {
                id: userShare.id,
                fullName: userShare.fullName,
                userName: userShare.userName,
                avatar: userShare.avatar,
                permission: userShare.permission
            }
        }));
    }

    updateSharePermission(id: string, userId: string, permission: number): Promise<ActionResult> {
        return axios.put(`${this.url}/${id}/share/${userId}?permission=${permission}`);
    }

    deleteShare(id: string, userId: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}/share/${userId}`);
    }

    getAttributeResult(): Promise<FilterAttributeResult[]> {
        return axios.get(`${this.url}/attributes`);
    }

    suggestion(searchTerm: string, type?: CustomerType, page = 1, pageSize = 10): Promise<SearchResult<CustomerSuggestionModel>> {
        return axios.get(`${this.url}/suggestions`, {
            params: {
                searchTerm,
                type,
                page,
                pageSize
            }
        })
    }

    getCustomerSalesChannelByManagerId(managerId: string): Promise<ActionResult<CustomerSalesChannelResult>> {
        return axios.get(`${this.url}/channels/${managerId}`);
    }

    async getConfig(): Promise<CustomerConfig | null> {
        try {
            return (await axios.get(`${this.url}/configs`)) as CustomerConfig;
        } catch (error) {
            return null;
        }
    }

    saveContact(customerId: string, contactIds: CustomerContact[]): Promise<ActionResult> {
        return axios.post(`${this.url}/${customerId}/contacts`, contactIds);
    }

    removeContact(customerId: string, id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${customerId}/contacts/${id}`);
    }

    async approve(id: string, isApprove: boolean): Promise<ActionResult> {
        return axios.put(`${this.url}/${id}/approve/${isApprove}`);
    }

    getPriceTableByCustomer(customerId: string, productIds: Array<number>): Promise<ActionResult<any>> {
        return axios.post(`${this.url}/${customerId}/price-tables`, productIds);
    }

    updateDescription(id: any, description: string, concurrencyStamp: string): Promise<ActionResult<string>> {
        return axios.put(`${this.url}/${id}/description`, {
            description,
            concurrencyStamp
        });
    }

    updateResource(id: any, resourceId: string, resourceName: string, concurrencyStamp: any): Promise<ActionResult<string>> {
        return axios.put(`${this.url}/${id}/resource`, {
            id: resourceId,
            name: resourceName,
            concurrencyStamp
        });
    }

    updateSalesStrategy(id: any, salesStrategyId: string, salesStrategyName: string, concurrencyStamp: any): Promise<ActionResult<string>> {
        return axios.put(`${this.url}/${id}/sales-strategy`, {
            id: salesStrategyId,
            name: salesStrategyName,
            concurrencyStamp
        });
    }

    updateClassify(id: any, classifyId: string, classifyName: string, concurrencyStamp: any): Promise<ActionResult<string>> {
        return axios.put(`${this.url}/${id}/classify`, {
            id: classifyId,
            name: classifyName,
            concurrencyStamp
        });
    }

    updateManager(id: string, managerId: string, managerFullName: string, concurrencyStamp: string): Promise<ActionResult<string>> {
        return axios.put(`${this.url}/${id}/manager`, {
            managerId,
            managerFullName,
            concurrencyStamp
        })
    }

    updateStatusApproveCustomer(ids: string[], isApprove: boolean = false): Promise<ActionResult<number>> {
        return axios.put(`${this.url}/is-approve`, {
            customerIds: ids,
            isApprove: isApprove
        });
    }

    updateCustomerResources(ids: string[], id: string, name: string): Promise<ActionResult<number>> {
        return axios.put(`${this.url}/resources`, {
            customerIds: ids,
            id: id,
            name: name
        });
    }

    updateSalesStrategies(ids: string[], id: string, name: string): Promise<ActionResult<number>> {
        return axios.put(`${this.url}/sales-strategies`, {
            customerIds: ids,
            id: id,
            name: name
        });
    }

    updateClassifies(ids: string[], id: string, name: string): Promise<ActionResult<number>> {
        return axios.put(`${this.url}/classifies`, {
            customerIds: ids,
            id: id,
            name: name
        });
    }

    updateSalesChannel(ids: string[], id: string): Promise<ActionResult<number>> {
        return axios.put(`${this.url}/sales-channel`, {
            customerIds: ids,
            salesChannelId: id
        });
    }

    UpdateContact(customerId: string, contact: CustomerContact): Promise<ActionResult> {
        return axios.put(`${this.url}/${customerId}/contacts`, contact);
    }

    delete(id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}`);
    }

    getAddressesByCustomer(customerId: string): Promise<ActionResult<any>> {
        return axios.get(`${this.url}/${customerId}/get-addresses`);
    }

    updateManagers(ids: string[], managerId: string): Promise<ActionResult<number>> {
        return axios.put(`${this.url}/managers`, {
            customerIds: ids,
            managerId: managerId
        });
    }

    getCustomerAttributeOptions() {
        return [
            new FilterAttributeResult("SalesChannelId", "Kênh bán hàng", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customer-groups/activated`, LogicType.And, DataType.String),
            new FilterAttributeResult("CreateTime", "Ngày tạo", "", "",
                true, AttributeType.DateTime, [], [], OperatorType.Between, "", LogicType.And, DataType.DateTime),
            new FilterAttributeResult("FoundingDate", "Ngày thành lập/Ngày sinh", "", "",
                true, AttributeType.DateTime, [], [], OperatorType.Between, "", LogicType.And, DataType.DateTime),
            new FilterAttributeResult("ManagerId", "Người quản lý", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("CreatorId", "Người tạo", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("Contacts.PhoneNumber", "Số điện thoại liên hệ", "", "",
                false, AttributeType.Input, [], [], OperatorType.Equal, "", LogicType.And, DataType.String),
            new FilterAttributeResult("Contacts.Email", "Email liên hệ", "", "",
                false, AttributeType.Input, [], [], OperatorType.Equal, "", LogicType.And, DataType.String),
            new FilterAttributeResult("TaxCode", "Mã số thuế", "", "",
                false, AttributeType.Input, [], [], OperatorType.Contains, "", LogicType.And, DataType.String),
            new FilterAttributeResult("Type", "Loại khách hàng", "", "",
                true, AttributeType.CheckList, [], ListCustomerType, OperatorType.Equal, "", LogicType.And, DataType.Byte),
            new FilterAttributeResult("ResourceId", "Nguồn khách hàng", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.CustomerResources}`, LogicType.And, DataType.String),
            new FilterAttributeResult("ClassifyId", "Phân loại khách hàng", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.CustomerClassify}`, LogicType.And, DataType.String),
            new FilterAttributeResult("PotentialLevelId", "Hạng KH", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.CustomerPotentialLevel}`, LogicType.And),
            new FilterAttributeResult("ClarificationId", "Phân cấp khách hàng", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.CustomerClarification}`, LogicType.And),
            new FilterAttributeResult("StrategyId", "Tình trạng khách hàng", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.CustomerSalesStrategy}`, LogicType.And),
            new FilterAttributeResult("FocusGroupId", "Nhóm khách hàng cần tập trung", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.CustomerFocusGroup}`, LogicType.And),
            new FilterAttributeResult("CompanySizeId", "Quy mô công ty", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.CustomerCompanySize}`, LogicType.And),
            new FilterAttributeResult("BusinessCareerId", "Ngành nghề kinh doanh", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.CustomerBusinessCareer}`, LogicType.And),
            new FilterAttributeResult("RevenueId", "Doanh thu", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/categories/suggestion/${CategoryType.Revenue}`, LogicType.And),
            new FilterAttributeResult("Gender", "Giới tính", "", "",
                true, AttributeType.CheckList, [], ListGender, OperatorType.Equal, "", LogicType.And, DataType.Byte),
            new FilterAttributeResult("IsApproved", "Trạng thái duyệt", "", "",
                true, AttributeType.CheckList, [], ListStatusApprove, OperatorType.Equal, "", LogicType.And, DataType.Bit),
        ]
    }

    getDefaultColumnSetting() {
        return [
            new SettingColumnSelect('Thao tác', 'actions', true),
            new SettingColumnSelect('Chọn', 'selection', true),
            new SettingColumnSelect('Mã khách hàng', 'code', false),
            new SettingColumnSelect('Tên khách hàng', 'name', true),
            new SettingColumnSelect('Tên ngắn', 'shortName', false),
            new SettingColumnSelect('Số điện thoại', 'phoneNumber', true),
            new SettingColumnSelect('Email', 'email', false),
            new SettingColumnSelect('Kênh bán hàng', 'salesChannelName', false),
            new SettingColumnSelect('Tỉnh/TP', 'addressPrimary.provinceName', false),
            new SettingColumnSelect('Quận/Huyện', 'addressPrimary.districtName', false),
            // new SettingColumnSelect('Đường/Phố', 'address.wards', false),
            new SettingColumnSelect('Địa chỉ', 'addressPrimary.address', true),
            new SettingColumnSelect('Người quản lý', 'managerFullName', false),
            new SettingColumnSelect('Website', 'website', false),
            new SettingColumnSelect('Mã số thuế', 'taxCode', false),
            new SettingColumnSelect('Người tạo', 'creatorFullName', false),
            new SettingColumnSelect('Người đại diện', 'representative', false),
            new SettingColumnSelect('Mô tả', 'description', false, false, true),
            new SettingColumnSelect('Ngày thành lập', 'foundingDate', false),
            new SettingColumnSelect('Giới tính', 'gender', false),
            new SettingColumnSelect('CMND', 'idCardNumber', false),
            // new SettingColumnSelect('Hoạt động', 'isActive', false),
            // new SettingColumnSelect('Trạng thái', 'status', false),
            new SettingColumnSelect('Loại khách hàng', 'type', true),
            new SettingColumnSelect('Nguồn khách hàng', 'resourceName', false),
            new SettingColumnSelect('Phân loại khách hàng', 'classifyName', false),
            new SettingColumnSelect('Hạng KH', 'potentialLevelName', false),
            new SettingColumnSelect('Phân cấp khách hàng', 'clarificationName', false),
            new SettingColumnSelect('Tình trạng khách hàng', 'strategyName', false),
            new SettingColumnSelect('Nhóm khách hàng cần tập trung', 'focusGroupName', false),
            new SettingColumnSelect('Quy mô công ty', 'companySizeName', false),
            new SettingColumnSelect('Ngành nghề kinh doanh', 'businessCareerName', false),
            new SettingColumnSelect('Doanh thu', 'revenueName', false),
            new SettingColumnSelect('Trạng thái duyệt', 'isApproved', false),
            new SettingColumnSelect('Thời gian tạo', 'createTime', true),
        ]
    }
}
