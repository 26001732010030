import Vue from 'vue';
import VueRouter from 'vue-router';
import {vuexOidcCreateRouterMiddleware} from 'vuex-oidc';
import store from '@/store/index';
import DebtList from "@/views/customer/detail/debt/DebtList.vue";

Vue.use(VueRouter);

const routes = [
    {
        // =============================================================================
        // MAIN LAYOUT ROUTES
        // =============================================================================
        path: '',
        // component: () => import('@/layouts/main/Main.vue'),
        component: () => import('@/layouts/main/Main.vue'),
        children: [
            // =============================================================================
            // Theme Routes
            // =============================================================================
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@/views/dashboard/Dashboard.vue')
            },
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/Home.vue')
            },
            {
                path: '/page2',
                name: 'page-2',
                component: () => import('@/views/Page2.vue')
            },
            {
                path: '/customer',
                name: 'listCustomer',
                component: () => import('@/views/customer/CustomerList.vue'),
                children: [
                    {
                        path: '/report',
                        name: 'customerReport',
                        component: () => import('@/views/customer/report/CustomerReport.vue')
                    }
                ]
            },
            {
                path: '/customer/synchronize',
                name: 'customerSynchronize',
                component: () => import('@/views/customer/Synchronize.vue')
            },
            {
                path: '/customer/detail/:id',
                name: 'customerDetail',
                component: () => import('@/views/customer/detail/CustomerDetail.vue'),
                children: [
                    {
                        path: '',
                        name: 'customerInsight',
                        component: () => import(
                            /* webpackTrunkName: "CustomerInsight" */ '@/views/customer/insight/CustomerInsight.vue'
                            )
                    },
                    {
                        path: 'note',
                        name: 'customerNoteList',
                        component: () =>
                            import(
                                /* webpackTrunkName: "CustomerNoteList" */ '@/views/customer/note/CustomerNoteList.vue'
                                )
                    },
                    {
                        path: 'email',
                        name: 'customerEmailList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerEmailList" */ '@/views/customer/email/CustomerEmailList.vue')
                    },
                    {
                        path: 'activity',
                        name: 'customerActivityList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerActivityList" */ '@/views/customer/activity/CustomerActivityList.vue')
                    },
                    {
                        path: 'quotation',
                        name: 'customerQuotationList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerActivityList" */ '@/views/customer/quotation/CustomerQuotationList.vue')
                    },
                    {
                        path: 'task',
                        name: 'customerTaskList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerTaskList" */ '@/views/customer/task/CustomerTaskList.vue')
                    },
                    {
                        path: 'guarantee',
                        name: 'customerGuaranteeList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerGuaranteeList" */ '@/views/customer/guarantee/CustomerGuaranteeList.vue')
                    },

                    {
                        path: 'order',
                        name: 'customerOrderList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerOrderList" */ '@/views/customer/order/CustomerOrderList.vue')
                    },
                    {
                        path: 'project',
                        name: 'customerProjectList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerProjectList" */ '@/views/customer/project/CustomerProjectList.vue')
                    },
                    {
                        path: 'call',
                        name: 'customerCallList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerCallList" */ '@/views/customer/call/CustomerCallList.vue')
                    },
                    {
                        path: 'debt',
                        name: 'debtList',
                        component: DebtList
                    }
                ]
            },
            {
                path: '/callCenter/:id/:callId',
                name: 'callCenter',
                component: () => import('@/views/customer/detail/CallCenterCustomerDetail.vue'),
                children: [
                    {
                        path: 'insight',
                        name: 'CC_customerInsight',
                        component: () => import(
                            /* webpackTrunkName: "CustomerInsight" */ '@/views/customer/insight/CustomerInsight.vue'
                            )
                    },
                    {
                        path: 'note',
                        name: 'CC_customerNoteList',
                        component: () =>
                            import(
                                /* webpackTrunkName: "CustomerNoteList" */ '@/views/customer/note/CustomerNoteList.vue'
                                )
                    },
                    {
                        path: 'email',
                        name: 'CC_customerEmailList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerEmailList" */ '@/views/customer/email/CustomerEmailList.vue')
                    },
                    {
                        path: 'activity',
                        name: 'CC_customerActivityList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerActivityList" */ '@/views/customer/activity/CallCenterCustomerActivityList.vue')
                    },
                    {
                        path: 'quotation',
                        name: 'CC_customerQuotationList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerActivityList" */ '@/views/customer/quotation/CustomerQuotationList.vue')
                    },
                    {
                        path: 'task',
                        name: 'CC_customerTaskList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerTaskList" */ '@/views/customer/task/CustomerTaskList.vue')
                    },
                    {
                        path: 'guarantee',
                        name: 'CC_customerGuaranteeList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerGuaranteeList" */ '@/views/customer/guarantee/CustomerGuaranteeList.vue')
                    },
                    {
                        path: 'order',
                        name: 'CC_customerOrderList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerOrderList" */ '@/views/customer/order/CustomerOrderList.vue')
                    },
                    {
                        path: 'project',
                        name: 'CC_customerProjectList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerProjectList" */ '@/views/customer/project/CustomerProjectList.vue')
                    },
                    {
                        path: 'call',
                        name: 'CC_customerCallList',
                        component: () =>
                            import(/* webpackTrunkName: "CustomerCallList" */ '@/views/customer/call/CustomerCallList.vue')
                    },
                    {
                        path: 'CC_debt',
                        name: 'CC_debtList',
                        component: DebtList
                    }
                ]
            },
            {
                name: 'customerGroup',
                path: '/customer-group',
                component: () => import('@/views/customer-group/CustomerGroupList.vue'),
                meta: {
                    breadcrumb: [
                        {title: 'Trang chủ', url: '/'},
                        {title: 'Khách hàng'},
                        {title: 'Kênh bán hàng', active: true},
                    ],
                    pageTitle: 'Kênh bán hàng',
                    rule: 'editor'
                },
            },
            {
                name: 'customerGroupSetting',
                path: '/customer-group-setting',
                component: () => import('@/views/customer-group/CustomerGroupConfig.vue'),
                meta: {
                    breadcrumb: [
                        {title: 'Trang chủ', url: '/'},
                        {title: 'Khách hàng'},
                        {title: 'Mã nhập xuất', active: true},
                    ],
                    pageTitle: 'Mã nhập xuất',
                    rule: 'editor'
                },
            },
            {
                path: '/contact',
                name: 'listContact',
                component: () => import(/* webpackTrunkName: "ContactLContactListist" */ '@/views/contact/ContactList.vue')
            },
            {
                path: '/orders',
                name: 'orderList',
                component: () => import('@/views/order/order-list/OrderList.vue')
            },
            {
                path: '/orders/logistic',
                name: 'orderList',
                component: () => import('@/views/order/order-list/OrderLogistic.vue')
            },
            {
                path: '/order-contract',
                name: 'orderContract',
                component: () => import('@/views/order/order-contract/OrderContractList.vue')
            },
            {
                path: '/order/:id',
                name: 'orderDetail',
                component: () => import('@/views/order/OrderDetail.vue')
            },
            {
                path: '/returns',
                name: 'returnList',
                component: () => import('@/views/order/order-return/ReturnList.vue')
            },
            {
                path: '/config',
                name: 'userConfig',
                component: () => import(/* webpackTrunkName: "UserConfig" */ '@/views/config/UserConfig.vue')
            },
            {
                path: '/category',
                name: 'category',
                component: () => import(/* webpackTrunkName: "category" */ '@/views/category/Category.vue')
            },
            {
                path: '/transit-point',
                name: 'transitPoint',
                component: () => import(/* webpackTrunkName: "transitPoint" */ '@/views/category/TransitPoint.vue')
            },
            {
                path: '/route',
                name: 'route',
                component: () => import(/* webpackTrunkName: "stress" */ '@/views/category/Route.vue')
            },
            {
                path: '/setup-key-code',
                name: 'setup-key-code',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/setup/ShortKey.vue')
            },
            {
                path: '/config-system',
                name: 'config-system',
                component: () => import(/* webpackTrunkName: "config-system" */ '@/views/config/System.vue'),
                meta: {
                    breadcrumb: [
                        {title: 'Trang chủ', url: '/'},
                        {title: 'Cấu hình'},
                        {title: 'Cấu hình hệ thống', active: true},
                    ],
                    pageTitle: 'Trang chủ',
                    rule: 'editor'
                },
            },
            {
                path: '/price-table-sale-channel',
                name: 'setup',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/config/PriceTable.vue')
            },
            {
                path: '/report-revenue',
                name: 'report-revenue',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/charts/RevenueReport.vue')
            },
            {
                path: '/email',
                redirect: '/email/inbox',
                name: 'email',
            },
            {
                path: '/email/:filter',
                component: () => import('@/views/email/Email.vue'),
                meta: {
                    rule: 'editor',
                    parent: 'email',
                    no_scroll: true,
                }
            },
            {
                path: '/truck',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/config/Truck.vue'),
                name: 'truck',
            },
            {
                path: '/payment_vouchers',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/cash-book/receipt/payment-voucher/payment-voucher.vue'),
                name: 'paymentVoucherList'
            },
            {
                path: '/payment_vouchers/add',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/cash-book/receipt/payment-voucher/payment-voucher-form.vue'),
                name: 'paymentVoucherForm'
            },
            {
                path: '/payment_vouchers/:id',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/cash-book/receipt/payment-voucher/payment-voucher-detail.vue'),
                name: 'paymentVoucherDetail'
            },
            {
                path: '/receipt_vouchers',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/cash-book/receipt/receiver-voucher/receiver-voucher.vue'),
                name: 'receiptVoucherList'
            },
            {
                path: '/receipt_vouchers/add',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/cash-book/receipt/receiver-voucher/receiver-voucher-form.vue'),
                name: 'receiverVoucherForm'
            },
            {
                path: '/receipt_vouchers/:id',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/cash-book/receipt/receiver-voucher/receiver-voucher-detail.vue'),
                name: 'receiverVoucherDetail'
            },
            {
                path: '/reports/vouchers',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/cash-book/cash-book-list.vue'),
                name: 'reportVoucher'
            },
            {
                path: '/return/:id',
                name: 'returnDetail',
                component: () => import('@/views/order/order-return/ReturnDetail.vue'),
                redirect: {
                    name: 'orderExchange'
                },
                children: [
                    {
                        path: '',
                        name: 'orderExchange',
                        component: () => import('@/views/order/order-return/OrderExchange.vue')
                    }
                ]
            },
            // ------------ Guarantee ------------------
            {
                path: '/guarantee',
                name: 'guaranteeList',
                component: () => import('@/views/guarantee/GuaranteeList.vue')
            },
            {
                path: '/guarantee-detail',
                name: 'guaranteeDetailList',
                component: () => import('@/views/guarantee/GuaranteeDetailList.vue')
            },
            {
                path: '/guarantee/add',
                name: 'guaranteeForm',
                component: () => import('@/views/guarantee/guarantee-form/GuaranteeForm.vue')
            },
            {
                path: '/guarantee/edit/:id',
                name: 'guaranteeEdit',
                component: () => import('@/views/guarantee/guarantee-form/GuaranteeForm.vue')
            },
            {
                path: '/guarantee/:id',
                name: 'guaranteeDetail',
                component: () => import('@/views/guarantee/guarantee-form/GuaranteeForm.vue')
            },
            {
                path: '/callCenter/:mobile/callId/:id',
                name: 'callCenter',
                component: () => import('@/views/customer/CallCenter.vue')
            },
            {
                path: '/callCenter/new/:mobile/:callId',
                name: 'newCustomer',
                component: () => import('@/views/customer/CallCenterCustomerQuickInsert.vue')
            },
            {
                path: '/marketing/report-gapone',
                name: 'gapone',
                component: () => import('@/views/gapone/GapOne.vue')
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '',
        component: () => import('@/layouts/full-page/FullPage.vue'),
        children: [
            // =============================================================================
            // PAGES
            // =============================================================================
            {
                path: '/pages/login',
                name: 'page-login',
                component: () => import('@/views/pages/Login.vue')
            },
            {
                path: '/pages/error-404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue')
            }, {
                path: '/callback',
                name: 'callback',
                component: () => import('@/components/auth/Callback.vue')
            }, {
                path: '/silent-callback',
                name: 'silentCallback',
                component: () => import('@/components/auth/SilentCallback.vue')
            },
            {
                path: '/no-permission',
                name: 'noPermission',
                component: () => import(/* webpackTrunkName: "ShortKey" */ '@/views/NoPermission.vue')
            },
            {
                path: '/orders/add',
                name: 'orderAdd',
                component: () => import('@/views/order/order-edit/OrderForm.vue'),
                // props: true
            },
            {
                path: '/orders/edit/:id',
                name: 'orderEdit',
                component: () => import('@/views/order/order-edit/OrderForm.vue')
            }
            // {
            //     path: '/orders/:id?/return',
            //     name: 'orderReturn',
            //     component: () => import('@/views/order/order-edit/OrderForm.vue'),
            //     props: { orderFormType: 1 }
            // },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/pages/error-404'
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes
});
router.beforeEach(vuexOidcCreateRouterMiddleware(store));

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
