import {ICustomerState} from './customer-state';
import {MutationTree} from 'vuex';
import {CustomerConfig} from '@/models/customer/CustomerConfig';

export const FETCH_CONFIG = 'FETCH_CONFIG';
export const SET_CUSTOMER_SEARCH_QUERY = 'SET_CUSTOMER_SEARCH_QUERY';

export const mutations: MutationTree<ICustomerState> = {
    [FETCH_CONFIG](state: ICustomerState, payload: CustomerConfig) {
        //console.log(payload);
        state.config = payload;
    },
};