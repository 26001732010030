import {CustomerType} from '@/constants/Customer';

export interface BranchResult {
    id: string;
    name: string;
}

export interface InventoryResult {
    id: string;
    name: string;
}


/**
 * Cấu hình khách hàng.
 */
export class CustomerConfig {
    /**
     * Cho phép xem kênh khác không.
     */
    allowViewAnotherSalesChannel: boolean;
    /**
     * Cho phép tạo trùng khách hàng không.
     */
    allowDuplicateCustomer: boolean;
    /**
     * Cho phép thay đổi sales channel không.
     */
    allowEditSalesChannel: boolean;
    /**
     * Mã kênh bán hàng mặc định là gì.
     */
    defaultSalesChannelId: string;
    /**
     * Tên kênh bán hàng mặc định.
     */
    defaultSalesChannelName: string;
    /**
     * Loại khách hàng mặc đinh: Cá nhân | doanh nghiệp.
     */
    defaultCustomerType: CustomerType;
    /**
     * Danh sách chi nhánh.
     */
    branches: BranchResult[];
    /**
     * Danh sách kho mặc định.
     */
    inventories: InventoryResult[];
    /**
     * Có phải là trưởng kênh không.
     */
    isSalesChannelLeader: boolean;
    /**
     * Có phải là sales admin không.
     */
    isSalesAdmin: boolean;

    constructor() {
        this.allowDuplicateCustomer = false;
        this.allowEditSalesChannel = false;
        this.allowViewAnotherSalesChannel = false;
        this.branches = [];
        this.inventories = [];
        this.isSalesAdmin = false;
        this.isSalesChannelLeader = false;
        this.defaultCustomerType = CustomerType.personal;
        this.defaultSalesChannelId = '';
        this.defaultSalesChannelName = '';
    }
}