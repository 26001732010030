import { Module } from 'vuex';
import { RootState } from '@/store/root-state';
import { IAppState } from "@/store/app/app-state";
import { actions } from "@/store/app/actions";
import { getters } from "@/store/app/getters";
import { mutations } from "@/store/app/mutations";

export const state: IAppState = {
    requestFailObject: undefined
};

export const AppStore: Module<IAppState, RootState> = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
