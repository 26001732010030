import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/store/root-state';
import { actions } from '@/store/actions';
import { mutations } from '@/store/mutations';
import state from '@/store/state';
import getters from '@/store/getters';
import { AuthStore } from '@/store/auth';
import { NoteStore } from '@/store/note';
import { ContactStore } from '@/store/contact';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from '@/config/oidc';
import { CustomerStore } from './customer';
import { FilterStore } from "@/store/filter";
import { AppStore } from "@/store/app";
import { OrderStore } from "@/store/order";

Vue.use(Vuex);
const store: StoreOptions<RootState> = {
    state,
    getters,
    mutations,
    actions,
    modules: {
        app: AppStore,
        auth: AuthStore,
        note: NoteStore,
        contact: ContactStore,
        customer: CustomerStore,
        filter: FilterStore,
        order: OrderStore,
        oidcStore: vuexOidcCreateStoreModule(oidcSettings,
            // Optional OIDC store settings
            {
                dispatchEventsOnWindow: true
            },
            // Optional OIDC event listeners
            {
                userLoaded: (user: any) => {

                },
                userUnloaded: () => console.log('OIDC user is unloaded'),
                accessTokenExpiring: () => console.log('Access token will expire'),
                accessTokenExpired: () => console.log('Access token did expire'),
                silentRenewError: () => console.log('OIDC user is unloaded'),
                // userSignedOut: () => new FirebaseUserTokenService().remove(),
                oidcError: (payload: any) => console.log(`An error occured at ${payload.context}:`, payload.error)
            })
    }
};

export default new Vuex.Store<RootState>(store);
