import { IContactState } from '@/store/contact/contact-state';
import { Module } from 'vuex';
import { RootState } from '@/store/root-state';
import { actions } from '@/store/contact/actions';
import { getters } from '@/store/contact/getters';
import { mutations } from '@/store/contact/mutations';
import { Contact } from '@/models/contact/Contact';

export const state: IContactState = {
    newContactAdded: new Contact(),
    listContactAdded: []
};

export const ContactStore: Module<IContactState, RootState> = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
