import axios from 'axios';
import store from '@/store/index';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = store.state.oidcStore.access_token;
    if (!token) {
        console.log('Access token not found');
    } else {
        config.headers.Authorization = `Bearer ${token}`;
    }
    // store.dispatch('loading', true);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // store.dispatch('loading', false);
    return response.data;
}, function (error) {
    if (error.response) {
        const status = error.response.status;
        if (status === 401) {
            //authService.login();
            //store.dispatch('signOutOidc');
            console.log(401);
        }

        if (status === 403) {
            //router.push({name: 'noPermission'});
            store.dispatch('app/setRequestFail', {
                code: status,
                message: 'Not have permission'
            })
        }

        if (status === 404) {
            //router.push({ name: 'page-error-404' });
            // store.dispatch('app/setRequestFail', {
            //     code: status,
            //     message: 'Not found'
            // })
            const data = error.response.data;
            return Promise.reject(data);
        }
        // store.dispatch('loading', false);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (status === 400) {
            const data = error.response.data;
            // Vue.prototype._init();
            // var vm = new Vue();

            // if (vm.$vs) {
            //     vm.$vs.notify({
            //         title: '',
            //         text: data.message,
            //         color: "danger"
            //     })
            // }
            // store.dispatch('app/setRequestFail', {
            //     code: status,
            //     message: data.message
            // })
            return Promise.reject(data);
        }

        if (status === 499) {
            const data = {
                message: "Có lỗi xảy ra. Xin vui lòng liên hệ với quản trị viên."
            };

            return Promise.reject(data);
        }
    }
    return Promise.reject(error);
});
