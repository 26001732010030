// export class CateType {
//     id: number | undefined;
//     name: string | undefined;
//
//     constructor() {
//     }
// }

export enum CategoryType {
    /// <summary>
    /// Nguồn khách hàng.
    /// </summary>
    CustomerResources = 0,
    /// <summary>
    /// Vùng khách hàng.
    /// </summary>
    CustomerArea = 1,
    /// <summary>
    /// Loại khách hàng.
    /// </summary>
    CustomerClassify = 2,
    /// <summary>
    /// Hạng KH
    /// </summary>
    CustomerPotentialLevel = 3,
    /// <summary>
    /// Quy mô công ty.
    /// </summary>
    CustomerCompanySize = 4,
    /// <summary>
    /// Ngành nghề kinh doanh.
    /// </summary>
    CustomerBusinessCareer = 5,
    /// <summary>
    /// Vai trò liên hệ.
    /// </summary>
    ContactRole = 6,
    /// <summary>
    /// Trạng thái.
    /// </summary>
    ProjectStatus = 7,
    /// <summary>
    /// Giai đoạn dự án.
    /// </summary>
    ProjectPhase = 8,
    /// <summary>
    /// Nguồn dự án.
    /// </summary>
    ProjectResources = 9,
    /// <summary>
    /// Nguồn vốn.
    /// </summary>
    ProjectCapital = 10,
    /// <summary>
    /// Kênh bán hàng.
    /// </summary>
    ProjectSaleChannel = 11,
    /// <summary>
    /// Loại giao dịch
    /// </summary>
    TransactionType = 12,
    /// <summary>
    /// Mức sống
    /// </summary>
    CustomerStandardLiving = 13,
    /// <summary>
    /// Loại địa chỉ.
    /// </summary>
    //        [Display(Name = "Loại địa chỉ")]
    //        CustomerAddressType = 14,
    /// <summary>
    /// Loại dự án.
    /// </summary>
    ProjectType = 15,
    /// <summary>
    /// Loại đối tác.
    /// </summary>
    PartnerType = 16,
    /// <summary>
    /// Khu vực thi công
    /// </summary>
    ConstructionArea = 17,
    /// <summary>
    /// Kết quả boq
    /// </summary>
    BOQResult = 18,
    /// <summary>
    /// Phần trăm cơ hội.
    /// </summary>
    PackageOutcome = 19,
    /// <summary>
    /// Lý do hồ sơ.
    /// </summary>
    ProjectSaleProfileReason = 20,
    /// <summary>
    /// Nghề nghiệp
    /// </summary>
    PersonalCustomerBusinessCareer = 21,
    /// <summary>
    /// Ngành nghề kinh doanh.
    /// </summary>
    TruckPayload = 22,
    /// <summary>
    /// Lý do hủy đơn hàng
    /// </summary>
    CancelOrderReason = 23,
    /// <summary>
    /// Segment
    /// </summary>
    ProjectSegment = 25,
    /// <summary>
    /// SpecStage
    /// </summary>
    ProjectSpecStage = 26,
    /// <summary>
    /// Key access class
    /// </summary>
    //CustomerKAC = 27,
    /// <summary>
    /// Chiến lược bán hàng
    /// </summary>
    CustomerSalesStrategy = 28,
    /// <summary>
    /// Nhóm tập trung
    /// </summary>
    CustomerFocusGroup = 29,
    /// <summary>
    /// Nhóm giai đoạn bán hàng
    /// </summary>
    SalesStageGroup = 30,
    /// <summary>
    /// Nguồn lead
    /// </summary>
    LeadSource = 31,
    /// <summary>
    /// Chi nhánh
    /// </summary>
    Branch = 32,
    /// <summary>
    /// Kho.
    /// </summary>
    Inventory = 33,
    /// <summary>
    /// Phân cấp khách hàng.
    /// </summary>
    CustomerClarification = 27,
    /// <summary>
    /// Tài khoản kế toán
    /// </summary>
    Accountant = 35,
    /// <summary>
    /// Danh mục nhập xuất
    /// </summary>
    ExportImportType = 36,

    /// <summary>
    /// Doanh thu
    /// </summary>
    Revenue = 37,

    /// <summary>
    /// Mô tả hoạt động
    /// </summary>
    BusinessActivity = 38,

    /// <summary>
    /// Loại hợp đồng
    /// </summary>
    ContractType = 39,

    /// <summary>
    /// Loại phiếu thu
    /// </summary>
    ReceiptType,

    /// <summary>
    /// Loại phiếu chi
    /// </summary>
    PaymentType = 41,

    /// <summary>
    /// Hình thức thanh toán
    /// </summary>
    PaymentMethod = 42
}
