import {ActionTree} from 'vuex';
import {RootState} from '@/store/root-state';
import {ICustomerState} from './customer-state';
import {FETCH_CONFIG} from "./mutations";
import {CustomerConfig} from '@/models/customer/CustomerConfig';
import {CustomerService} from "@/services/customer/CustomerService";

export const actions: ActionTree<ICustomerState, RootState> = {
    async fetchConfig({commit, state}, payload: CustomerConfig) {
        const customerConfig = await new CustomerService().getConfig();
        commit(FETCH_CONFIG, customerConfig ? customerConfig : new CustomerConfig());
    },
};
