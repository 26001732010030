import axios from 'axios';
import { DebtResult, DebtType } from "@/views/customer/detail/debt/debt.result";
import { SearchResult } from "@/models/SearchResult";

export class DebtService {
    url = `${process.env.VUE_APP_GATEWAY_URL}accountant/api/v1/debts`;

    search(customerId: string, fromDate?: string | null, toDate?: string | null, type: DebtType = DebtType.AccountReceivable,
           page = 1, pageSize = 20): Promise<SearchResult<DebtResult>> {
        return axios.get(this.url, {
            params: {
                customerId,
                fromDate,
                toDate,
                type,
                page,
                pageSize
            }
        })
    }
}
