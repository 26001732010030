import { Gender } from '@/constants/gender';

export class Contact {
    id: string | undefined;
    fullName: string | undefined;
    phoneNumber: string | undefined;
    email: string | undefined;
    image: string | undefined;
    dob: string | undefined;
    gender: Gender | undefined;

    constructor (
        fullName?: string,
        phoneNumber?: string,
        email?: string,
        gender?: Gender,
        image?: string
    ) {
        this.fullName = fullName;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.gender = gender || Gender.male;
        this.image = image || '';
    }
}
