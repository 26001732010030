import { Module } from 'vuex';
import { RootState } from '@/store/root-state';
import { mutations } from '@/store/note/mutations';
import { actions } from '@/store/note/actions';
import { NoteResult } from '@/models/note/NoteResult';
import { getters } from '@/store/note/getters';

export interface INoteState {
    listItem: NoteResult[],
    totalRows: number
}

export const state: INoteState = {
    listItem: [],
    totalRows: 0
};

export const NoteStore: Module<INoteState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
